<template>
  <div class="about-us">
    <div class="introduce">
      <h2>{{$t('AboutUs.introduce.title')}}</h2>
      <div class="vision-and-mission">
        <div class="vision">
          <img src="./../assets/about_vision.png" alt="Our Vision" />
          <h3>{{$t('AboutUs.introduce.sub_title1')}}</h3>
          <p>{{$t('AboutUs.introduce.v_text')}}</p>
        </div>
        <div class="mission">
          <img src="./../assets/about_mission.png" alt="Our Mission" />
          <h3>{{$t('AboutUs.introduce.sub_title2')}}</h3>
          <p>{{$t('AboutUs.introduce.m_text')}}</p>
        </div>
      </div>
    </div>
    <div class="office">
      <h3>{{$t('AboutUs.office.title')}}</h3>
      <div class="sliders">
        <swiper :options="swiperOption">
          <swiper-slide v-for="item in officeImgs" :key="item.id">
            <img :src="item.imgUrl">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="figures">
      <h2>{{$t('AboutUs.figures.title')}}</h2>
      <p>{{$t('AboutUs.figures.text1')}}</p>
      <p>{{$t('AboutUs.figures.text2')}}</p>
      <!--<p>{{$t('AboutUs.figures.text3')}}</p>-->
      <div class="data-content">
        <div class="data-list-item">
          <span>1.9 <i>{{$t('AboutUs.figures.millions')}}</i></span>
          <h4>{{$t('AboutUs.figures.data_title1')}}</h4>
        </div>
        <div class="data-list-item">
          <span><i>{{$t('AboutUs.figures.more_than')}}</i> 671000</span>
          <h4>{{$t('AboutUs.figures.data_title2')}}</h4>
        </div>
        <div class="data-list-item">
          <span><i>{{$t('AboutUs.figures.USD')}}</i> 127 <i>{{$t('AboutUs.figures.millions')}}</i></span>
          <h4>{{$t('AboutUs.figures.data_title3')}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'AboutUs',
  components: {
    swiper, swiperSlide
  },
  data: function() {
    return {
      officeImgs: [
        { id: 'Id01', imgUrl: require('./../assets/about_local_team_daily01.png') },
        { id: 'Id02', imgUrl: require('./../assets/about_local_team_daily02.png') },
        { id: 'Id03', imgUrl: require('./../assets/about_local_team_daily03.png') }
      ],
      swiperOption: {
        effect: 'coverflow',
        spaceBetween: 10, // 缩略图间距
        slidesPerView: 3,
        centeredSlides: true,
        initialSlide: 2,
        loop: true,
        grabCursor: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true
        }
      }
    }
  },
  methods: {
    showDetails(val) {
      if (this.corporateShow === val) {
        this.corporateShow = ''
      } else {
        this.corporateShow = val
      }
    }
  }
}
</script>

<style lang="scss">
.about-us {
  width: 100%;
  .introduce {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 56px;
    h2 {
      font: normal bold 46px Arial;
      color: #2a2a2a;
      padding: 80px 0 82px;
    }
    .vision-and-mission {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .vision,
      .mission {
        width: 520px;
        img {
          width: 100%;
          height: auto;
        }
        h3 {
          padding: 25px 0 42px;
          font: normal bold 28px Arial;
          color: #2a2a2a;
        }
        p {
          text-align: justify;
          font: normal 500 18px Arial;
          letter-spacing: 1px;
          color: #5e5e5e;
          text-align: justify;
        }
      }
    }
  }
  .corporate-teams {
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: center;
    > h3 {
      font: normal bold 46px Arial;
      color: #2a2a2a;
      padding: 80px 0 80px;
    }
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      li {
        margin-right: 55px;
        .avatar {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: auto;
            cursor: pointer;
          }
          .disabled-show {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            border-radius: 50%;
            background: rgba(40, 102, 171, 0.3);
          }
        }
        h3 {
          font: normal bold 20px Arial;
          color: #585858;
          padding: 25px;
        }
      }
    }
    .details {
      background: #f8f8f8;
      padding: 20px 25px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        img {
          width: 230px;
          height: auto;
          border-radius: 8px;
        }
        h3 {
          margin-top: 10px;
          font: normal 500 28px Arial;
          color: #2a2a2a;
          span {
            color: #0062cc;
            margin-left: 12px;
          }
        }
      }
      p {
        width: 825px;
        text-align: justify;
        font: normal 500 20px/36px Arial;
        color: #585858;
      }
    }
  }
  .office {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
    > h3 {
      font: normal bold 46px Arial;
      color: #2a2a2a;
      padding: 80px 0 120px;
    }
    .sliders {
      width: 100%;
      overflow: hidden;
      img {
        width: 400px;
        height: 247px;
        border-radius: 10px;
      }
    }
  }
  .figures {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
    h2 {
      font: normal bold 45px Arial;
      color: #2A2A2A;
      padding: 80px 0 35px;
    }
    p {
      font: normal 500 22px Arial;
      color: #656565;
      margin-bottom: 20px;
    }
    .data-content {
      display: flex;
      justify-content: space-around;
      margin-top: 55px;
      .data-list-item {
        text-align: center;
        span {
          font: normal bold 70px Arial;
          color: #5490EA;
          i {
            font-size: 32px;
          }
        }
        h4 {
          font: normal 500 18px Arial;
          color: #656565;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
